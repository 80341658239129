import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"



const MLTechniques = () => {
  //javascript
  const title = 'Machine Learning-Based Fragility Analysis';
  const metatitle = 'Machine Learning-Based Fragility Analysis';
  const description = 'Meta Description for Machine Learning-Based Fragility Analysis';
  const metadescription = description;
  return(
    <Layout bodyclass="ml-tech" title={title}>
      <SEO
        title={metatitle}
        description={metadescription}
      />
      <p>Under Development</p>
      
    </Layout>
  )
}

export default MLTechniques
